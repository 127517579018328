@import './../AnimationHeightWidth.scss';
.slide{
    margin-bottom: 10px;
    width: 100%;
    
}
.slideImageWrapper{
    display:block;
}

@media (min-width:900px) and (orientation: landscape) {
    .slide{
        margin-bottom:0;
        object-fit: contain;
        object-position: 50% 50%;
        max-width: 100%;
        width: unset;
        margin-right:30px;
        height:100%;
        max-height:$height1300;
    }
    .slideImageWrapper{
        display:flex;
        flex: 0 0 auto;
        padding:0;
        height: 100%;
        
    }
    .slideImageWrapper:first-of-type{
        margin-left:286px;
        padding-left:36px;
    }
    .slideImageWrapper:last-of-type>img{
        margin-right:20px;
    }
}
@media (min-width:1300px) and (orientation: landscape) {
    .slideImageWrapper{
        width: auto;
        height: 100%;
    }
}