.name{
    font-size:1.4em;
    font-weight:300;
    width:100%;  
}
.br{
    display:none;
}
@media (min-width:900px){
    .name{
    margin:0 auto 30px auto;
    }
    .br{
        display:inline
    }
}
