@import'../Galleries/AnimationHeightWidth.scss';
.menu{
    height:$height1300;
    max-height: calc(100vh - 140px);
    position: relative;
}
.navItems{
    margin-top:116px;
    padding:0;
    line-height:1.3em;
}
.edycje{
    margin-top:4px;
    margin-left:5px;
}
.edycjeNone{
    display:none;
}
.navItem{
    list-style: none;
    margin:0;
    cursor: pointer;
    
    
    &Link{
        transition: font-weight .1s ease-in-out;
        &Active{
            font-weight: 600;
        }
    }
}
.socialIcons{
margin-top:30px;
opacity:0.6;
img{
    width:12px;
}
}
.language{
    
    position: absolute;
    bottom:0;
    button{
        background-color: transparent;
        border:none;
        padding:0;
        opacity:0.9;
        cursor: pointer;
    }
    span{
        padding:4px;
    }
}
//.navItem:nth-last-child(3){
//    margin-top:30px;
//}
.socialIcons>a{
    margin-right:8px;
}
@media (max-width:900px){
    .menu{
        height:calc(100vh - 162px);
    }
    .language{
        bottom:100px;
    }
}
@media (min-width:900px) and (orientation: landscape) {
    .navItems{
        margin-top:0px;
    }
}