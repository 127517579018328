@import './../AnimationHeightWidth.scss';
.info{
    text-align: right;
    cursor: pointer;
    border:none;
    background-color: #FFF;
    font-size:1em;
}

@mixin infoContent{
    text-align: center;
    width: calc(100% - 6px)
    }

.infoContent{
    &FadeIn{
            @include infoContent;
            animation-name: slideDown;
            animation-duration: 1s;
            animation-iteration-count: 1;
            
    }
    &FadeOut{
            @include infoContent;
            animation-name: slideUp; 
            animation-duration: 1s;
            animation-iteration-count: 1;
            
    }
}

.description{
  text-align: justify;
  margin:0;
  margin-bottom:30px;
}

@media (min-width:900px) and (orientation: landscape) {
  .wrapper{
  position: absolute;
  left:0;
  overflow: auto;
  min-height:calc(100vh - 36px - 72px);
  width:100%;
      
  }
  .description{
    width: 300px;
    margin-right:30px;
    display: flex;
    flex: 0 0 auto;
    padding: 0;
    height: 100%;
    text-align: justify;
  }
  .description:first-child{
    margin-left:286px;
    padding-left:36px;
  }
  .galleryScroll{
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-bottom:10px;   
      width:100%;    
  }

  .info{
      text-align: left;
      padding:0;
      margin-left:3px;
      margin-top:6px;
      position: sticky;
      left: calc(25% + 46px);
  }
  .infoContent{
    &FadeIn, &FadeOut{
      position: sticky;
      left: calc(25% + 46px);
      text-align: left;
      width:59%;
    }
  }

}

@media (min-width:1300px) and (orientation: landscape) {
  .galleryScroll{
    width:100%;
  }
  .wrapper{
    min-height:calc(100vh - 72px - 72px);
  }
}
