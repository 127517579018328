@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap');

$margin:36px;


html{
  
  position: relative;
}
body {
  margin: $margin;
  font-family: 'Sorts Mill Goudy';
  
  font-weight: 200;
  min-height: calc(100vh - (2 * #{$margin}));
  overflow-x: hidden;
  box-sizing: border-box;
  font-size:13px;
  z-index: -1;
  margin:0px auto;
  min-height: 100vh;
  line-height: 1.3em;
}
p{
  min-height: 1em;
}
button{
  font-family: 'Sorts Mill Goudy';
  font-weight:200;
  font-size: 13px;
}
a{
  text-decoration: none;
}
a:link, a:visited, a:hover, a:active  {
  color:#000;
}
button:focus {
  outline: -webkit-focus-ring-color none;
}
h1{
  font-weight:normal;
}
@media (max-width:899px), (orientation: portrait) {
  body{
    padding-bottom:36px;
    padding: 16px;
    min-height:100vh;
  }
}
@media (min-width:900px) and (orientation: landscape) {
  body{
    
    padding: 36px 36px 0;

  }
 
}

@media(min-width:1300px) and (orientation: landscape) {
  body{
    padding:calc(2 * #{$margin}) 36px $margin 36px;
    
  }
}