@media (min-width:900px) and (orientation: landscape) {
    .wrapper{
        margin:0 auto;
        
    }
    .aside{
       // width:calc(25% + 10px);
        position:fixed;
        height:80%;
        z-index: 2;
        background-color: #FFF;
    }
    .cover{
        position: fixed;
        width: 286px;
        height: 80%;
        background-color: #FFF;
        left: 0;
        //z-index: 1;
    }
    .main{
        margin-left:286px;
        padding-top:71.3px;
    }
}



