@import './../AnimationHeightWidth.scss';

@mixin infoContent{
    text-align: center;
    width: calc(100% - 6px);

}

.info{
    @include infoContent;
}
.infoContent{
    &FadeIn{
            @include infoContent;
            animation-name: slideDown;
            animation-duration: 1s;
            animation-iteration-count: 1;
            
    }
    &FadeOut{
            @include infoContent;
            animation-name: slideUp; 
            animation-duration: 1s;
            animation-iteration-count: 1;
            
    }
}

@media(min-width:900px){
    .infoContentFadeIn, .infoContentFadeOut, .info{
        width: $width900;
        margin-top:2em;
    } 
}
@media(min-width:1300px){
    .infoContentFadeIn, .infoContentFadeOut, .info{
        width: $width1300;
        max-width: 900px;
    } 
}

