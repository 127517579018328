.wrapper{
    height:100%;
    width: 100%;
    box-sizing: border-box;
    margin:0;
    background-color: rgba(255, 255, 255, 0.95);
    top:0;
    position:fixed;
    left: 0;
    padding: 0 36px 0 36px;
}