@import'./../../components/Galleries/AnimationHeightWidth.scss';


.images{
    width:100%;
    max-width: 800px;
    img{
    display: block;
   width:100%;
   
   margin:0 auto;
   margin-bottom:30px;
   //max-height:55vh;
   object-fit: contain;
    }
}
   .nazwa{
    margin-top: 0;
    font-size: 13px;
    }

@media (max-width:899px){
    .images{
        margin:0 auto;
    }
}
@media (min-width:900px) {
    .wrapper{
        display: flex;
       // margin-left: calc((100vw - 60px) / 2 - 286px);
       // transform: translateX(-50%);
        margin-right: 0%;
    }
    
    .text{
        padding-left:30px;
        width:33%
    }
    .opis{
        margin-top:1em;
    }
}
@media(min-width:1300px) and (orientation: landscape) {
    .wrapper{
        margin-left: calc((100vw - 60px) / 2 - 286px);
        transform: translateX(-50%);
    }
  }