.wrapper{
   // margin-right:-28px;
    padding-bottom: 36px;
    position: relative;
    max-width:900px;
    p{
        margin:0;
    }
}
.link{
    z-index: 1;
}
p.dzial:not(:first-of-type){
    margin-top:1em
}
p.dzialSecond{
    margin-top:0;
}
.container{
    display:grid;
    grid-template-columns: minmax(34px, auto) 4fr;
}
.container:first-of-type{
    margin-bottom:2em;
    text-align: justify;
}
.container:last-of-type{
    margin-bottom:36px;
}
.year{
padding-right:6px;
}
.download{
    position: absolute;
    bottom:36px;
    left:calc(286px + 36px);
    display: flex;
    align-items: center;
    img{
        margin-left:1px;
        width:15px;
        vertical-align: middle;
    }
}
.sectionWrapper{
    
    h3{
        font-weight:300;
        font-size: 1em;
        margin: 1em 0;
       
    }
}
.sectionWrapper:first-of-type{
    h3{
        margin-top:0;
    }
}
@media (max-width:899px){
    .download{
        bottom:-28px;
        margin-bottom: 36px;
        right:16px;
        left: unset;
    }
}
@media (min-width:900px){
    .wrapper{
        max-width:calc(0.8 * (100vw - 300px));
    }
    .enter{
        display:none
    }
}
@media (min-width:1200px){
    .wrapper{
        max-width:900px;
    }
    .enter{
        display:none
    }
}