.hamburger {
    padding: 10px;
    padding-right:0;
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    margin: 0;
    margin-top: 5px;
}
.hamburger:focus {
  outline: -webkit-focus-ring-color none;
  }
.hamburgerBox {
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
    }

@mixin hamburgerLine {
    width: 100%;
    height: 1px;
    background-color:#000;
    position: absolute;
    }

.hamburgerInner{
    @include hamburgerLine;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .1s  ease-in-out;
}

.hamburgerInner::before,
.hamburgerInner::after {
  @include hamburgerLine;
  
  content: '';
  left: 0;
  transition: transform  .2s ease-in-out;
} 

.hamburgerInner::before {
  top: -7px;
}

.hamburgerInner::after {
  top: 7px;
}

.hamburgerActive{
  @extend .hamburger;
  position: relative;
  z-index:1;
}
.hamburgerActive .hamburgerInner {
  background-color: transparent;
}

.hamburgerActive .hamburgerInner:before {
  transform: translateY(7px) rotate(45deg);
}

.hamburgerActive .hamburgerInner:after {
  transform: translateY(-7px) rotate(-45deg);
}


