@import "react-alice-carousel/lib/scss/alice-carousel.scss";
@import './../AnimationHeightWidth.scss';


.alice-carousel, .alice-carousel__wrapper{
    overflow: hidden;
}
.alice-carousel__{
    &prev-btn-custom:focus, &next-btn-custom:focus{
        outline:none;
        outline: -webkit-focus-ring-color none;
    }

    &prev-btn-custom:hover, &next-btn-custom:hover{
    opacity:0.7;
    }
    @mixin buttons {
        width: unset;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        background-color:transparent;
        border:none;
        cursor: pointer;
        opacity:1;
    }
    &prev-btn-custom{
        @include buttons;
        left:10px
    }
    &next-btn-custom{
        @include buttons;
        right:10px
    }
    &stage-item{
        text-align: center;
        vertical-align: middle;
    }
    &slide{
        //object-fit: contain;
       //object-position: 50% 50%;
       max-width: calc(100% - 2px);
       // width:100%;
       
    }
    &text{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        width: calc(72% - 156px);
        text-align: justify;
        max-width:450px;
        
        
    }
    &buttonWrapper{
        text-align: right;
        padding-right:10px;
    }
    &info:focus{
        outline:none;
    }
    &info{
        justify-self: end;
        text-align: right;
        cursor: pointer;
        border:none;
        background-color: #FFF;
        font-size:1em;
        margin-top:10px;
    }
}
.alice-carousel-custom-wrapper{
    position: relative;
}
@media(max-width:450px){
    .alice-carousel__{
        &text{
            font-size:3.3vw;
        }
    }

    
}
@media(max-width:899px), (orientation: portrait) {
    .alice-carousel-custom-wrapper{
        position: absolute;
        left: 0;
        overflow-x: hidden;
        width: 100%;
    }
    .alice-carousel__prev-btn-custom{
        left:0;
        padding-left:0;
        display:none
    } 
    .alice-carousel__next-btn-custom{
        right:0;
        padding-right:0;
        display:none
    }
    .alice-carousel__prev-btn-custom>img, .alice-carousel__next-btn-custom>img{
        width:20px;
    }
    .alice-carousel__text{
        //width: calc(100% - 52px); jakby były przyciski widoczne
        width: calc(100% - 32px);
    }

}
@media (min-width:900px) and (orientation: landscape) {
    .alice-carousel, .alice-carousel__wrapper, 
    .alice-carousel-custom-wrapper{
        height: $height1300;
        width: $width1300;
    }
    .alice-carousel-custom-wrapper{
        margin-left: calc((100vw - 72px) / 2 - 286px);
        transform: translateX(-50%);
    }
    .alice-carousel__{
        &slide{
            //max-width: calc(100% - 150px);
            height: calc(100% - 2px);
           // margin:0 auto;
        }
        &stage-item{
            vertical-align: middle;
        }
    
        &buttonWrapper{
            width: $width1300;
            padding-right: 36px;
        }
        &text{
            font-size:0.9vw;
            width:calc(100% - 156px);
        }
         &prev-btn-custom{
            left:-100px;
            opacity:0.5;
        }
        &next-btn-custom{
            right:-100px;
            opacity:0.5;
        }
    }
}
@media (min-width:1000px) and (orientation: landscape) {
    .alice-carousel__{
        &text{
            font-size:1vw;
        }
    }
}

@media (min-width:1300px) and (orientation: landscape) {
    .alice-carousel, .alice-carousel__wrapper, 
    .alice-carousel-custom-wrapper{
            height: $height1300;
           width: $width1300;
            
           // max-width:66.6%;
    }
    .alice-carousel__{
        &buttonWrapper{
            width: $width1300;
            max-width:900px;
        }
        &text{
            font-size:inherit;
        }
    }
}